<template>
    <div>
        <PageHeader v-if="isHeaderAlive"/>
        <b-container>
            <b-row class="mt-3">
                <keep-alive>
                    <router-view  v-if="isRouterAlive"/>
                </keep-alive>
            </b-row>
            <b-row class="mt-4">
                <PageFooter />
            </b-row>
        </b-container>
    </div>

</template>

<script>
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'

export default {
    name: 'App',
    components: {
      PageHeader,
      PageFooter
    },
    provide() {
        return {
            header_reload: this.header_reload,
            route_reload: this.route_reload,
            reload: this.reload
        }
    },
    data() {
      return {
        isHeaderAlive: true,
        isRouterAlive: true
      }
    },
    methods: {
        header_reload() {
            this.isHeaderAlive = false
            this.$nextTick(() => {
                this.isHeaderAlive = true
            })
        },
        route_reload() {
            this.isRouterAlive = false
            this.$nextTick(() => {
                this.isRouterAlive = true
            })
        },
        reload() {
            this.header_reload()
            this.route_reload()
        }
    }
}
</script>

<style>
.custom-breadcrumb {
    background-color: #f8f9fa;
}
.custom-fox {
    background-color: #e9ecef;
}
.sticky-breadcrumb {
    position: sticky;
    top: 0;
    z-index: 1020; /* 高于页面大部分元素 */
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee; /* 添加底部边框 */
}
</style>

