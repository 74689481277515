<template>
    <div class="container">
        <b-row align-h="center">
            <b-col cols="8" >
                <b-card title="用户登陆">
                    <b-card-text>
                        <b-form>
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-2 col-form-label">邮箱</label>
                                <div class="col-sm-10">
                                    <b-form-input v-model="this.login_form.username" type="text" class="form-control" required></b-form-input>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputPassword3" class="col-sm-2 col-form-label">密码</label>
                                <div class="col-sm-10">
                                    <b-form-input v-model="this.login_form.password" type="password" class="form-control" required></b-form-input>
                                </div>
                            </div>
                            <vue-recaptcha
                                sitekey="6LeXOQ8hAAAAAOs5mlB3r72NEaPqTQk17FsqYFv4"
                                :loadRecaptchaScript="true"
                                recaptchaHost="www.recaptcha.net"
                                @verify="verifyMethod"
                            >
                            </vue-recaptcha>
                        </b-form>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

    </div>
        <!--
         -->
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    name: "PageUserLogin",
    components: { VueRecaptcha },
    data() {
        return {
            login_form: {
                username: '',
                password: '',
                google_code: '',
            },
            siteKey: 'AIzaSyAa4Yyv0fG8v2aoXghS-nVTcaR2D6hWbKs'
        }
    },
    methods: {
        verifyMethod(response){
            console.log(response);
            this.login_form.google_code = response;
        }
    }
}
</script>
