<template>
    <b-container>
        <div class="mx-auto">
            <div v-for="(item, index) in pg_data" :key="index" class="m-2 ml-3">
                <b-badge variant="info">{{ (new Date(item['kicked_time_at'])).toLocaleString() }}</b-badge>
                <!--在 <b-badge variant="secondary">{{ item['kicked_from_name'] }}</b-badge>-->
                在 <b-badge variant="secondary">{{ item['kicked_from_name'].substr(0,30)+"..." }}</b-badge>
                踢出玩家
                <b-badge variant="warning">
                    <a @click="jump(item['personaId'])" href="#"> {{ item['display_name'] }} </a>
                </b-badge>
                <a v-if="item['kicked_type'] >= 3">  具体理由: <b-badge variant="info"> {{ item['kicked_reason'] }} </b-badge></a>
            </div>
        </div>
    </b-container>
</template>

<script>
// v-for="(item, index) in reply_list" :key="index"
import {request} from "@/api";

export default {
    name: "PageKickedLog",
    data() {
        return {
            tmp: false,
            pg_data: null
        }
    },
    methods: {
        async init() {
              this.pg_data = await request('GET', '/inner_api/kicked_log?size=20&pn=1')
              this.pg_data = this.pg_data['logs']
        },
        async jump(personaId) {
            const case_data = await request('GET', '/case/pid/' + personaId);
            const target = case_data["case_id"]
            await this.$router.push({path: "/case/" + target})
        }
    },
    created() {
        this.init()
    }
}
</script>

<style scoped>

</style>