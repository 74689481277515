<template>
    <div id="editor">
        <b-overlay :show="waiting" rounded="sm">
            <mavon-editor v-model="report"
                         :editable="!waiting"
                         ref="box"
                         @imgAdd="imgAdd"
                         :boxShadow="false"
                         @change="changed"
                         :autofocus="false"
                         :html="false"
            />
        </b-overlay>
    </div>
</template>

<script>
import { upload_image } from "@/api";

export default {
    name: "MarkedDownInputBox",
    data() {
        return {
            report: null,
            waiting: false,
            markdown_value: null,
            toolbars: {
                bold: true, // 粗体
                italic: true, // 斜体
                header: false, // 标题
                underline: false, // 下划线
                strikethrough: false, // 中划线
                mark: true, // 标记
                link: true, // 链接
                imagelink: true, // 图片链接
                undo: true, // 上一步
                redo: true, // 下一步
                trash: true, // 清空
                /* 1.4.2 */
                navigation: true, // 导航目录
                /* 2.2.1 */
                subfield: true, // 单双栏模式
                preview: true, // 预览
            }
        }
    },
    methods: {
        imgAdd (pos, $file) {
            this.$nextTick().then(() => {
                this.waiting = true
            })
            upload_image($file)
                .then(res => {
                    this.$refs.box.$img2Url(pos, res)
                })
                .catch(e => {
                    this.$bvModal.msgBoxOk("图片上传失败! 需要重新上传, 否则最后会无法显示图片! 详情: " + e, {title: '警告!'})
                        .then(() => {this.$refs.box.$refs.toolbar_left.$imgDelByFilename($file.name)})
                })
                .finally(() => {
                    this.$nextTick().then(() => {
                        this.waiting = false
                    })
                })
        },
        changed (value, render) {
            this.markdown_value = render
        }
    }
}
</script>

<style scoped>
.v-note-wrapper {
    z-index: 0 !important
}

</style>