<template>
    <div>
        <h4>用户管理</h4>
        <hr>
        <b-card title="搜索用户">
            <b-card-body>
                <b-form inline>

                        <b-form-input
                            id="input-1"
                            v-model="email"
                            type="email"
                            placeholder="注册邮箱"
                            required
                        ></b-form-input>
                    <b-button variant="primary">搜索</b-button>
                </b-form>
            </b-card-body>
        </b-card>
        <b-card title="用户详情">
            <b-card-body v-if="user_info">
                请先搜索用户....
            </b-card-body>
            <b-card-body v-else>
                <b-form-group inline>
                    <label class="mr-sm-2" for="inline-form-custom-select-pref">权限修改</label>
                    <b-form-select>
                        <b-form-select-option :value="null">请选择....</b-form-select-option>
                        <b-form-select-option value="admin">管理员</b-form-select-option>
                        <b-form-select-option value="user">普通用户</b-form-select-option>
                    </b-form-select>
                </b-form-group>

            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import {get_permlevel} from '@/cookies'


export default {
    name: "SubUserList",
    data: function() {
        return {
            user_info: null,
            email: "",
            modal_loading: false,
            current_operate: 0,
            callback_change_pw: null,
            callback_change_nickname: null,
            perm_level: get_permlevel,
        }
    },
    methods: {
    },
    mounted () {
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {   //屏幕尺寸变化就重新赋值
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    }
}
</script>

<style scoped>

</style>