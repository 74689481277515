<template>
    <b-container fluid="sm">
        <b-overlay :show="loading" rounded="sm">
            <div class="custom-fox">
                <b-breadcrumb class="custom-fox">
                    <b-breadcrumb-item v-on:click="next_case('-case_id')">
                        <b-icon icon="caret-left-fill" scale="1.25" shift-v="1.25" aria-hidden="true" ></b-icon>
                        查看上一条
                    </b-breadcrumb-item>
                    <b-breadcrumb-item to="/list">封禁列表</b-breadcrumb-item>
                    <b-breadcrumb-item active>{{ this.current_EAID + '(' + this.case_id + ')' }}</b-breadcrumb-item>
                    <b-breadcrumb-item v-on:click="next_case('case_id')">
                        查看下一条
                        <b-icon icon="caret-right-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                    </b-breadcrumb-item>
                </b-breadcrumb>
            </div>
            <b-card class="custom-fox">
                <div class="ml-2 mr-2">
                <b-card-title class="mt-2"><h1>{{current_EAID}}</h1></b-card-title>
                <b-alert show v-bind:variant="warn_box_class_activate">
                    <strong>警告!</strong> {{ warn_box_msg }}
                    <p v-if="expired_at >= 0"> 此人的自证到期时间: {{ (new Date(expired_at * 1000)).toLocaleString() }}</p>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <hr>
                    <p class="mb-0">如果对此案件有疑惑, 请注册账号并直接回复, 处理案件的管理员能够看到您的回复.</p>
                </b-alert>
                <b-alert show variant="danger" v-if="report_EAID.toLowerCase() !== current_EAID.toLowerCase()">
                    <strong>警告!</strong> 此人当前的游戏ID和被举报时记录的不同! 被举报时的ID: {{ report_EAID }}
                </b-alert>
                    <b-overlay :show="history_EAID_loading" rounded="sm">
                <b-card title="历史ID" fluid="sm" aria-expanded="true" class="mt-3">
                        <div v-if="history_EAID.length > 0">
                            <b-table striped hover
                                     :items="history_EAID"
                                     :fields="[{key:'data', sortable: true, label: '记录日期'}, {key:'change_to', label: 'OriginId'}]"
                                     :no-provider-paging="true" :per-page="perPage" :current-page="currentPage">
                            </b-table>
                            <div class="row" v-if="perPage <= totalRows">
                                <div class="mx-auto">
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        :per-page="perPage"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="history_EAID.length === 0 && !history_EAID_loading">
                            <p>未能正确获取到该玩家的历史ID，如果频繁出现该问题，请反馈给管理员！</p>
                        </div>
                </b-card>
        </b-overlay>
                <b-card title="举报详情" class="mt-3">
                    <b-card-sub-title class="mb-0">
                        <p>[{{ new Date(report_at).toLocaleString() }}] 举报者: {{ reported_by['nickname'] }}</p>
                        <p v-if="perm_level() >= 255" >注册邮箱: {{ reported_by["username"] }} | 用户UID： {{ reported_by["uid"] }} | 绑定的橘子OriginId {{ reported_by["origin_userId"] }}</p>
                    </b-card-sub-title>
                    <div>
                        战绩查询 |
                        <a :href="'https://battlefieldtracker.com/bf1/profile/pc/' + this.current_EAID" target="_blank">BTR (BF1)</a> |
                        <a :href="'https://battlefieldtracker.com/bfv/profile/origin/' + this.current_EAID + '/overview'" target="_blank">BTR (BFV)</a>
                    </div>
                    <div v-if="this.snapshot_url !== null">
                        <a :href="this.snapshot_url">战绩快照</a>
                    </div>
                    <b-card-body v-html="report_reason"></b-card-body>
                </b-card>
                <b-card title="回复&处理情况" v-show="reply_list.length > 0" class="mt-3">
                    <div v-for="(item, index) in reply_list" :key="index">
                        <b-card :title="get_comment_title(item)" :id="'reply_' + item['reply_id']" class="mt-3">
                            <b-card-sub-title>
                                {{ get_second_title(item) }}
                                <p v-if="perm_level() >= 255">
                                    邮箱 {{ item["operator"]["username"] }} | UID {{ item["operator"]["uid"] }} | OriginId {{ item["operator"]["origin_userId"] }}
                                </p>
                            </b-card-sub-title>
                            <b-card-text class="mt-3" v-html="item['comment']">
                            </b-card-text>
                            <b-card-footer v-if="perm_level() > 16 && item['new_status'] === -1" bg-variant="">
                                <b class="btn-link mr-1" v-on:click="set_hide(item['reply_id'])"
                                   v-if="!item['is_hid']">设置为仅管理员可见</b>
                                <b class="btn-link mr-1" v-on:click="set_un_hide(item['reply_id'])"
                                   v-else>取消仅管理员可见</b>
                            </b-card-footer>
                        </b-card>
                    </div>

                </b-card>
                </div>
            </b-card>

            <PageCaseDetailsAdmin v-if="is_login()" :perm_level="perm_level()" :appeal_allowed="appeal" :on_reply_complete="reload_by_reply"/>
            <scroll-button></scroll-button>
        </b-overlay>
    </b-container>
</template>

<script>
import {get_permlevel, is_login, get_eauid} from '@/cookies'
import PageCaseDetailsAdmin from '@/components/PageCaseDetailsAdmin.vue'
import { request } from '@/api'
import ScrollButton from "@/components/ScrollButton.vue";

export default {
    inject: ['reload'],
    data: function(){
        return {
            reported_by: "",
            current_EAID: "",
            current_status: null,
            report_EAID: "",
            warn_box_class_activate: "alert alert-danger alert-dismissible fade show",
            warn_box_class_list: [
                "primary", // 未处理
                "danger", // 已封禁
                "secondary", // 无效
                "success", // 解封
                "info", // 自证中
                "success" // 刷枪
            ],
            warn_box_msg_list: [
                "该案件暂未处理! 请等待管理员处理本案件!",
                "该案件已经被判定为 [封禁], 这意味着该账号已经被列入BFEAC黑名单.",
                "该案件已经被判定为 [无效], 这意味着该玩家目前并未被BFEAC封禁.",
                "该案件的申诉已经被受理, 所以目前我们解封了他的账号.",
                "当事人申请了自证, 故在自证期间给予该状态. 若未按时完成, 将根据情况重新判定.",
                "该案件已经被判定为 [刷枪], 这意味着该玩家目前并未被BFEAC封禁."
            ],
            warn_box_msg: null,
            report_reason: null,
            snapshot_url: null,
            history_EAID: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 25],
            reply_list: [],
            perm_level: get_permlevel,
            appeal: false,
            report_at: null,
            loading: true,
            case_id: "-1",
            expired_at: -1,
            history_EAID_loading: true
        }
    },
    methods: {
        next_case(direction) {
            request('GET', `/case/get_next_case?current_case_id=${this.case_id}&direction=${direction}&status=${this.current_status}&return_all_info=false`)
                    .then(response => {
                        if (response['have_next_case']) {
                            this.$router.push({name: 'case', params: {id: response['case_id']}})
                        } else {
                            this.$bvModal.msgBoxOk("没有下一个了哦！")
                        }
                    }).catch(e => {
                        this.$bvModal.msgBoxOk(e, {title: '警告!'})
                    })

        },
        reload_by_reply() {
            this.get_case_details(this.$route.params.id)
            document.getElementById("reply_" + this.data.reply_list[this.data.reply_list.length-1]['reply_id']);
        },
        get_history_id(personaId) {
            this.history_EAID_loading = true
            request('GET', '/utils/history_EAID/pid/' + personaId)
                .then(res => {
                    this.history_EAID = res
                    this.totalRows = this.history_EAID.length
                })
                .catch(() => {
                    this.history_EAID = []
                    this.totalRows = 0
                }).finally(
                () => {
                    this.history_EAID_loading = false
                }
            )
        },
        parse_data(data) {
            this.current_EAID = data['target']['current_name']
            this.report_EAID = data['target']['report_name']
            this.current_status = data.current_status
            this.warn_box_class_activate = this.warn_box_class_list[data.current_status]
            this.warn_box_msg = this.warn_box_msg_list[data.current_status]
            this.report_reason = data['reason']
            this.reported_by = data["operator"]
            if (data["operator"]["is_anonymous"]){
                this.reported_by['nickname'] = "匿名用户 #" + data['operator']['nickname']
            }
            this.appeal = data['target']['user_Id'] === get_eauid() && data.current_status === 1
            this.reply_list = data['reply']
            this.report_at = data['created_at']
            this.get_history_id(data['target']['personaId'])
            this.snapshot_url = data['battlog_snapshot']
            this.expired_at = data['appeal_expired_at']
        },
        get_case_details(case_id) {
            this.loading = true
            request('GET', '/case/' + case_id)
                .then(data => {
                    this.parse_data(data)
                })
                .catch(e => {
                    this.$bvModal.msgBoxOk(e, {title: '警告!'})
                })
                .finally(() => {
                    this.loading = false
                    if (this.$route.params.reply_id !== null) {
                        document.getElementById("reply_" + this.$route.params.reply_id);
                    }
                })

        },
        is_login,
        get_second_title(data) {
            let date = new Date(data['time']).toLocaleString()
            let text = "[" + date + "] ";
            if (data['is_hid']) {
                text = "[该回复仅管理员可见] " + text
            }

            switch (data['new_status']) {
                case -1:
                    return text + " 回复:"
                case 1:
                    return text + " 将案件状态设置为 => BFEAC封禁"
                case 2:
                    return text + " 将案件状态设置为 => 无效"
                case 3:
                    return text + " 将案件状态设置为 => 解封"
                case 4:
                    return text + " 将案件状态设置为 => 等待自证完成"
                case 5:
                    return text + " 将案件状态设置为 => 刷枪"
                case 0:
                    return text + " 将案件重置为 [未处理] 等待管理员二次处理."
                case -100:
                    return text + " 修改了当前案件的举报板块."
                case -101:
                    return text + " 被举报人申诉"
            }
        },
        get_comment_title(data) {
            if (data['operator']['is_anonymous']) {
                if (data['operator']['perm_level'] >= 16) {
                    return "匿名管理员 #" + data['operator']['nickname']
                }
                return "匿名用户 #" + data['operator']['nickname']
            }
            return data['operator']['nickname']
        },
        set_hide(reply_id) {
            request('GET', '/case/reply/' + reply_id + '/hide')
                .then(() => {
                    this.$bvModal.msgBoxOk('设置成功')
                    this.get_case_details(this.$route.params.id)
                })
                .catch(e => {
                    this.$bvModal.msgBoxOk(e, {title: '警告!'})
                })
        },
        set_un_hide(reply_id) {
            request('GET', '/case/reply/' + reply_id + '/un_hide')
                .then(() => {
                    this.$bvModal.msgBoxOk('解除成功')
                    this.get_case_details(this.$route.params.id)
                })
                .catch(e => {
                    this.$bvModal.msgBoxOk(e, {title: '警告!'})
                })
        }
    },
    activated() {
        if (this.case_id !== this.$route.params.id) {
            this.case_id = this.$route.params.id
            this.get_case_details(this.$route.params.id)
        } else {
            if (this.$route.params.reply_id !== null) {
                document.getElementById("reply_" + this.$route.params.reply_id).scrollIntoView();
            }
        }

    },
    watch: {
        '$route' (to, from) {
            if (to.params.id && from.params.id && to.params.id !== from.params.id) {
                this.case_id = this.$route.params.id
                this.get_case_details(this.$route.params.id)
            }
        }
    },
    components: {
        ScrollButton,
        PageCaseDetailsAdmin
    }
}
</script>