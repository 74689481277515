<template>
    <b-container>
        <div>
            <b-card class="shadow" id="fadein">
                    <router-view />
            </b-card>
        </div>



    </b-container>
</template>

<script>
export default {
    name: "PageUser"
}
</script>

<style scoped>

</style>