<template>
    <div>
        <b-button v-if="showTop" variant="primary" @click="scrollToTop" class="scroll-to-button" style="bottom: 80px;">
            去页首
        </b-button>
        <b-button v-if="showBottom" variant="secondary" @click="scrollToBottom" class="scroll-to-button">
            去页尾
        </b-button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showTop: false,
            showBottom: true
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            const totalHeight = document.body.scrollHeight;
            const windowHeight = window.innerHeight;
            if (windowHeight * 2 < totalHeight) {
                // 判断当前滚动位置是在页面的上半部分还是下半部分
                if (scrollPosition > totalHeight / 2) {
                    this.showTop = true;
                    this.showBottom = false;
                } else {
                    this.showTop = false;
                    this.showBottom = true;
                }
            } else {
                this.showTop = false;
                this.showBottom = false;
            }
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        scrollToBottom() {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style>
.scroll-to-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
}
</style>