<template>
    <div id="adminPage">
        <b-jumbotron>
            <b-card title="回复">
                <b-card-body >
                    <b-form-checkbox
                        v-if="appeal_allowed === true"
                        id="checkbox-1"
                        v-model="appeal"
                        name="checkbox-1"
                        :value=true
                        :unchecked-value=false
                    >
                        将本次回复作为申诉
                    </b-form-checkbox>
                    <b-form-group label="处理方式:" v-if="perm_level >= 16">
                        <b-select v-model="selected"
                                  :options="[
                                  { text: '仅回复', value: -1},
                                  { text: '封禁', value: 1},
                                  { text: '无效举报', value: 2 },
                                  { text: '自证通过', value: 3 },
                                  { text: '自证', value: 4 },
                                  { text: '刷枪', value: 5},
                                  { text: '需要重判', value: -100},
                                  { text: '更换举报板块', value: -1000}
                              ]"
                        />
                    </b-form-group>
                    <b-form-group v-show="selected === -1000 && perm_level >= 16">
                        <b-select v-model="selected_resend"
                                  :options="[
                                      {text: '战地1', value: '1'},
                                      {text: '战地5', value: '5'},
                                      {text: '其他分类', value: '0'},
                                  ]">

                        </b-select>
                    </b-form-group>
                    <b-form-group v-show="selected === 4 && perm_level >= 16">
                        <b-select v-model="selected_expired"
                                  :options="[
                                      {text: '自证时间: 3天', value: '259200'}
                                  ]">

                        </b-select>
                    </b-form-group>
                </b-card-body>
                <b-card-text>
                    <MarkedDownInputBox ref="reply_box" />
                </b-card-text>
                <b-card-footer>
                    <b-button variant="outline-primary float-right" v-on:click="submit_reply()" class="pull-right">提交</b-button>
                </b-card-footer>
            </b-card>
        </b-jumbotron>
        
    </div>
</template>

<script>
import MarkedDownInputBox from "@/components/MarkedDownInputBox";
import { request } from '@/api'

export default {
    inject: ['reload'],
    props: ['perm_level', 'appeal_allowed', 'on_reply_complete'],
    data() {
        return {
            selected: -1,
            selected_resend: 0,
            selected_expired: '259200',
            subtype: -1,
            reply: null,
            appeal: false
        }
    },
    methods: {
        async submit_reply() {
            try {
                const reply_value = this.$refs.reply_box.markdown_value
                this.$refs.reply_box.waiting = true  // 显示转圈等待的效果
                if (reply_value === '') {
                    await this.$bvModal.msgBoxOk("未填写任何回复!")
                    return
                }
                if (this.appeal) {
                    await request('POST', '/case/' + this.$route.params.id + "/appeal", {reply: reply_value})
                } else if (this.selected === -100) {
                    await request('POST', '/case/' + this.$route.params.id + "/reset", {reply: reply_value})
                } else if (this.selected === -1000) {
                    await request('POST', '/case/' + this.$route.params.id + "/reset?to=" + this.selected_resend, {reply: reply_value})
                } else if (this.selected === 4) {
                    if (this.selected_expired === 0) {
                        await this.$bvModal.msgBoxOk("无效的自证时长!")
                        return
                    }
                    await request('POST', '/case/' + this.$route.params.id + "/set_appeal_status?expired_time=" + this.selected_expired, {reply: reply_value, type: this.selected})
                } else if (this.selected >= 0) {
                    await request('POST', '/case/' + this.$route.params.id + "/judge", {reply: reply_value, type: this.selected, sub_type: 1})
                } else {
                    await request('POST', '/case/' + this.$route.params.id + "/reply", {reply: reply_value})
                }
                await this.$bvModal.msgBoxOk("回复已经提交!")
                if (this.on_reply_complete) {
                    this.on_reply_complete()
                }
            } catch (e) {
                await this.$bvModal.msgBoxOk(e[1], {title: '警告!'})
            } finally {
                this.$refs.reply_box.waiting = false  // 结束转圈等待
            }
            
        }
    },
    components: {
        MarkedDownInputBox
    }
}
</script>
