<template>
    <b-container>

        <b-card title="信息流" sub-title="" class="shadow" id="fadein">

            <b-card-body>
                <b-overlay :show="loading" rounded="sm">
                    <b-container>
                        <b-row>
                            <b-form-radio-group v-model="select_status"
                                                :options="[
                                { text: '回复', value: -1},
                                { text: '申诉', value: -101 },
                                { text: '封禁', value: 1 },
                                { text: '自证过期', value: -1000 },
                            ]"
                                                @change="init()" />
                        </b-row>
                        <hr>
                        <div v-for="(item, index) in pg_data" :key="index" class="m-2 ml-3">
                            <b-row>
                                <b-badge variant="info">{{ (new Date(item['time'])).toLocaleString() }}</b-badge>
                                <b-badge variant="warning"> {{ item['operator']['char_type'] + "  " + item['operator']['nickname'] }}</b-badge>
                                在案件编号<router-link :to="'/case/' + item['case_id'] + '/' + item['reply_id']"> {{ item['case_id'] }} </router-link>
                                中有过对应操作.
                            </b-row>
                        </div>
                        <b-row>
                            <b-pagination
                                v-model="current_page"
                                :total-rows="pg_num"
                                :per-page="20"
                                @change="init()"
                            ></b-pagination>
                        </b-row>
                    </b-container>
                </b-overlay>
            </b-card-body>

        </b-card>
    </b-container>
</template>

<script>
// v-for="(item, index) in reply_list" :key="index"
import {request} from "@/api";

export default {
    name: "PageWaterFall",
    data() {
        return {
            select_status: -1,
            pg_num: 1,
            pg_data: null,
            current_page: 1,
            loading: false
        }
    },
    methods: {
        async init() {
            this.loading = true
            let url = '/waterfall/admin?size=75&pn=' + this.current_page + "&category=" + this.select_status
            if (this.select_status === -1000) {
                url = '/waterfall/appeal_timeout?size=75&pn=' + this.current_page
            }
            this.pg_data = await request('GET', url)
            this.pg_num = this.pg_data["count"]
            this.pg_data = this.pg_data["data"]
            this.loading = false
        },
    },
    created() {
        this.init()
    }
}
</script>

<style scoped>

</style>