<template>
    <div>
        <h4>注册新用户</h4>
        <hr>
        <b-form>
            <b-form-group label="用户名(登陆)">
                <b-form-input v-model="register_username" />
            </b-form-group>
            <b-form-group label="昵称(显示)">
                <b-form-input v-model="register_nickname" />
            </b-form-group>
            <b-form-group label="密码">
                <b-form-input v-model="register_password" />
            </b-form-group>
            <b-form-group label="权限">
                <b-select v-model="register_perm_level" :options="[{ text: '普通用户', value: '0'},{ text: '管理员', value: '1'}]"
                                       />
            </b-form-group>
            <b-button variant="outline-primary float-right" class="pull-right" v-on:click="register">注册</b-button>
        </b-form>
    </div>

</template>

<script>
import {request} from "@/api";

export default {
    name: "SubUserRegister",
    data() {
        return {
            register_username: null,
            register_nickname: null,
            register_password: null,
            register_perm_level: 0
        }
    },
    methods: {
        // username: str
        // nickname: str
        // password: str
        // perm_level: int
        register() {
            request('POST', '/user-mgr/register', {username: this.register_username,
            nickname: this.register_nickname, password: this.register_password, perm_level: this.register_perm_level})
                .then(() => {
                    this.$bvModal.msgBoxOk("注册成功!")
                })
                .catch((e) => {
                    this.$bvModal.msgBoxOk(e)
                })
        }
    }
}
</script>

<style scoped>

</style>